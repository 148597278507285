import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Reducers are cool, and you need no library to use them`}</li>
      <li parentName="ul">{`They make your React code more predictable and help to fix bugs`}</li>
      <li parentName="ul">{`Notification API in a hook`}</li>
      <li parentName="ul">{`Web Audio API alarm sound, of course, in a hook`}</li>
    </ul>
    <p><strong parentName="p">{`Repo`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://github.com/ChopChopOrg/academy"
      }}>{`https://github.com/ChopChopOrg/academy`}</a>{` `}<br parentName="p"></br>{`
`}<strong parentName="p">{`App`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://chop-chop-academy.netlify.com"
      }}>{`https://chop-chop-academy.netlify.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      